import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Container, Form, Button, Row, Col, Accordion, Modal, ListGroup } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Gallery from "react-photo-gallery";
import { checkAuth, getSeries, addSerie, getSeriePhotos, uploadPhoto, addSeriePhoto, getExpos, addExpo, updateExpo, getExpoPhotos, addExpoPhoto, deleteSeriePhotos, deleteExpoPhotos, deleteSerie, deleteExpo, getGalleries, addGallery, getGalleryPhotos, addGalleryPhoto, deleteGalleryPhotos, deleteGallery } from '../../api/api';
import removeKeys from '../../services/removeKeys';
import SelectedImage from "../../components/SelectedImage";

import './Home.css';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api`;

interface Props {}

const Home: React.FC<Props> = () => {

	const navigate = useNavigate();
	const [selected, setSelected] = useState<string>('series');
	// Series
	const [series, setSeries] = useState<any[]>([]);
	const [seriePhotos, setSeriePhotos] = useState<{ id: number; src:string; width:number; height:number }[]>([]);
	const [showAddSerie, setShowAddSerie] = useState(false);
	const [serieTitle, setSerieTitle] = useState('');
	const [showAddSeriePhoto, setShowAddSeriePhoto] = useState(false);
	const [seriePhotoTitle, setSeriePhotoTitle] = useState('');
	const [seriePhotoDate, setSeriePhotoDate] = useState('');
	const [uploadedFile, setUploadedFile] = useState<any>(null);
	// Expos
	const [expositions, setExpositions] = useState<any[]>([]);
	const [activeKeys, setActiveKeys] = useState<any[]>([]);
	const [expoPhotos, setExpoPhotos] = useState<{ id: number; src:string; width:number; height:number }[]>([]);
	const [showAddExpo, setShowAddExpo] = useState(false);
	const [expoTitle, setExpoTitle] = useState('');
	const [expoDescription, setExpoDescription] = useState('');
	const [showAddExpoPhoto, setShowAddExpoPhoto] = useState(false);
	const [expoPhotoTitle, setExpoPhotoTitle] = useState('');
	const [expoPhotoDate, setExpoPhotoDate] = useState('');
	const [expoPhotoLocation, setExpoPhotoLocation] = useState('');
	// Galleries
	const [galleries, setGalleries] = useState<any[]>([]);
	const [galleryPhotos, setGalleryPhotos] = useState<{ id: number; src:string; width:number; height:number }[]>([]);
	const [showAddGallery, setShowAddGallery] = useState(false);
	const [galleryTitle, setGalleryTitle] = useState('');
	const [showAddGalleryPhoto, setShowAddGalleryPhoto] = useState(false);
	const [galleryPhotoTitle, setGalleryPhotoTitle] = useState('');
	const [galleryPhotoDate, setGalleryPhotoDate] = useState('');
	const [galleryPhotoLocation, setGalleryPhotoLocation] = useState('');

	const [error, setError] = useState<string | null>(null);
	const [success, setSuccess] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [currentSerie, setCurrentSerie] = useState<number | null>(null);
	const [currentExpo, setCurrentExpo] = useState<number | null>(null);
	const [selectAll, setSelectAll] = useState(false);
	const [selectedSeriePhotos, setSelectedSeriePhotos] = useState<number[]>([]);
	const [selectedExpoPhotos, setSelectedExpoPhotos] = useState<number[]>([]);
	const [currentGallery, setCurrentGallery] = useState<number | null>(null);
	const [selectedGalleryPhotos, setSelectedGalleryPhotos] = useState<number[]>([]);

	const toggleSelectAll = () => {
		setSelectAll(!selectAll);
	};

	const imageSerieRenderer = ({ index, left, top, key, photo }:any) => (
			<SelectedImage
			selected={selectAll ? true : false}
			key={key}
			margin={"2px"}
			index={index}
			photo={photo}
			left={left}
			top={top}
			selectedPhotos={selectedSeriePhotos}
			setSelectedPhotos={setSelectedSeriePhotos}
			/>
			);

	const imageExpoRenderer = ({ index, left, top, key, photo }:any) => (
			<SelectedImage
			selected={selectAll ? true : false}
			key={key}
			margin={"2px"}
			index={index}
			photo={photo}
			left={left}
			top={top}
			selectedPhotos={selectedExpoPhotos}
			setSelectedPhotos={setSelectedExpoPhotos}
			/>
			);

	const imageGalleryRenderer = ({ index, left, top, key, photo }:any) => (
			<SelectedImage
			selected={selectAll ? true : false}
			key={key}
			margin={"2px"}
			index={index}
			photo={photo}
			left={left}
			top={top}
			selectedPhotos={selectedGalleryPhotos}
			setSelectedPhotos={setSelectedGalleryPhotos}
			/>
			);

	const handleDeleteSerie = async (serieId:number) => {

		setIsLoading(true);

		try {
			const deleteSerieResponse = await deleteSerie(serieId);

			console.log('RESPONSE deleteSerieResponse', deleteSerieResponse);

			setIsLoading(false);
			
			if (deleteSerieResponse.error) {
				setError(deleteSerieResponse.error);
				return;
			}

			if (deleteSerieResponse.message)
				setSuccess(deleteSerieResponse.message);

			// reset states
			setError('');

			// refetch series list
			requestSeries();

		} catch (err: any) {
			console.log('CATCH ERR', err, err.message);

			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while deleting serie. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
	};

	const handleDeleteSeriePhotos = async () => {

		console.log('BEFORE selectedSeriePhotos', selectedSeriePhotos);

		setIsLoading(true);
		
		if (selectedSeriePhotos.length <= 0)
			return alert("Please select pictures !");

		try {
			const deleteSeriePhotosResponse = await deleteSeriePhotos({ photo_ids: selectedSeriePhotos });

			console.log('RESPONSE deleteSeriePhotosResponse', deleteSeriePhotosResponse);

			setIsLoading(false);
			
			if (deleteSeriePhotosResponse.error) {
				setError(deleteSeriePhotosResponse.error);
				return;
			}

			if (deleteSeriePhotosResponse.message)
				setSuccess(deleteSeriePhotosResponse.message);

			// reset states
			setError('');
			setSelectedSeriePhotos([]);

			// refetch serie photos list
			requestSeriePhotos(currentSerie);

		} catch (err: any) {
			console.log('CATCH ERR', err, err.message);

			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while delete photo. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
	};

	const handleDeleteExpo = async (expoId:number) => {

		setIsLoading(true);

		try {
			const deleteExpoResponse = await deleteExpo(expoId);

			console.log('RESPONSE deleteExpoResponse', deleteExpoResponse);

			setIsLoading(false);
			
			if (deleteExpoResponse.error) {
				setError(deleteExpoResponse.error);
				return;
			}

			if (deleteExpoResponse.message)
				setSuccess(deleteExpoResponse.message);

			// reset states
			setError('');

			// refetch series list
			requestExpos();

		} catch (err: any) {
			console.log('CATCH ERR', err, err.message);

			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while deleting expo. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
	};

	const handleDeleteExpoPhotos = async () => {

		console.log('BEFORE selectedExpoPhotos', selectedExpoPhotos);

		setIsLoading(true);
		
		if (selectedExpoPhotos.length <= 0)
			return alert("Please select pictures !");

		try {
			const deleteExpoPhotosResponse = await deleteExpoPhotos({ photo_ids: selectedExpoPhotos });

			console.log('RESPONSE deleteExpoPhotosResponse', deleteExpoPhotosResponse);

			setIsLoading(false);
			
			if (deleteExpoPhotosResponse.error) {
				setError(deleteExpoPhotosResponse.error);
				return;
			}

			if (deleteExpoPhotosResponse.message)
				setSuccess(deleteExpoPhotosResponse.message);

			// reset states
			setError('');
			setSelectedExpoPhotos([]);

			// refetch expo photos list
			requestExpoPhotos(currentExpo);

		} catch (err: any) {
			console.log('CATCH ERR', err, err.message);

			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while deleting photo. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
	};


	const handleDeleteGallery = async (galleryId:number) => {

		setIsLoading(true);

		try {
			const deleteGalleryResponse = await deleteGallery(galleryId);

			console.log('RESPONSE deleteGalleryResponse', deleteGalleryResponse);

			setIsLoading(false);
			
			if (deleteGalleryResponse.error) {
				setError(deleteGalleryResponse.error);
				return;
			}

			if (deleteGalleryResponse.message)
				setSuccess(deleteGalleryResponse.message);

			// reset states
			setError('');

			// refetch series list
			requestGalleries();

		} catch (err: any) {
			console.log('CATCH ERR', err, err.message);

			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while deleting gallery. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
	};

	const handleDeleteGalleryPhotos = async () => {

		console.log('BEFORE selectedGalleryPhotos', selectedGalleryPhotos);

		setIsLoading(true);
		
		if (selectedGalleryPhotos.length <= 0)
			return alert("Please select pictures !");

		try {
			const deleteGalleryPhotosResponse = await deleteGalleryPhotos({ photo_ids: selectedGalleryPhotos });

			console.log('RESPONSE deleteGalleryPhotosResponse', deleteGalleryPhotosResponse);

			setIsLoading(false);
			
			if (deleteGalleryPhotosResponse.error) {
				setError(deleteGalleryPhotosResponse.error);
				return;
			}

			if (deleteGalleryPhotosResponse.message)
				setSuccess(deleteGalleryPhotosResponse.message);

			// reset states
			setError('');
			setSelectedGalleryPhotos([]);

			// refetch gallery photos list
			requestGalleryPhotos(currentGallery);

		} catch (err: any) {
			console.log('CATCH ERR', err, err.message);

			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while deleting photo. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
	};

	const logoutRequest = async () => {
	    try {
	      removeKeys();
	      navigate('/0626dashboard');
	    } catch(e) {
	      console.log('CATCH ERROR', e);
	    }
	  }

	// Add new serie
	const handleCloseAddSerie = () => {
		setError(null);
		setShowAddSerie(false);
	};
	const handleShowAddSerie = () => setShowAddSerie(true);

	const handleSerieTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSerieTitle(e.target.value);
	};

	const handleSubmitAddSerie = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setIsLoading(true);
		
		if (!serieTitle)
			return alert("Please enter serie title !");

		try {
			const addSerieResponse = await addSerie({ name: serieTitle });

			console.log('RESPONSE addSerieResponse', addSerieResponse);

			setIsLoading(false);
			
			if (addSerieResponse.error) {
				setError(addSerieResponse.error);
				return;
			}

			if (addSerieResponse.message)
				setSuccess(addSerieResponse.message);

			// reset states
			setError('');
			setSerieTitle('');

			// refetch serie photos list
			requestSeries();

			handleCloseAddSerie();

		} catch (err: any) {
			console.log('CATCH ERR', err, err.message);

			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while file upload. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
	};

	// Add new serie's photo
	const handleCloseAddSeriePhoto = () => {
		// setCurrentSerie(null);
		setError(null);
		setShowAddSeriePhoto(false);
	}
	const handleShowAddSeriePhoto = (serieId:number) => {
		setCurrentSerie(serieId);
		setShowAddSeriePhoto(true);
	};

	const handleSeriePhotoTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSeriePhotoTitle(e.target.value);
	};
	const handleSeriePhotoDateChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSeriePhotoDate(e.target.value);
	};
	const handleInputPhotoChange = (e: ChangeEvent<HTMLInputElement>) => {
		console.log('E TARGET', e, e.target, e.target?.files);
		e.target?.files && setUploadedFile(e.target.files[0]);
	};

	const handleSubmitAddSeriePhoto = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setIsLoading(true);

		console.log('ICI QCASPAS', currentSerie, uploadedFile, seriePhotoTitle, seriePhotoDate);
		
		if (!currentSerie || !uploadedFile || !seriePhotoTitle || !seriePhotoDate)
			return alert("Please fill all fields !");

		let formData = new FormData();
		formData.append('image_url', uploadedFile, uploadedFile?.name);

		try {
			const uploadResponse = await uploadPhoto(formData);

			console.log('RESPONSE uploadResponse', uploadResponse);
			if (uploadResponse.error) {
				setIsLoading(false);
				setError(uploadResponse.error);
				return;
			}

			if (uploadResponse.message)
				setSuccess(uploadResponse.message);

			if (!uploadResponse.filename) {
				setIsLoading(false);
				return alert("Server upload error !");
			}

			if (!currentSerie) {
				setIsLoading(false);
				return alert("Select photo serie");
			}

			const addSeriePhotoResponse = await addSeriePhoto(currentSerie, { title: seriePhotoTitle, filename: uploadResponse.filename, date: seriePhotoDate });

			console.log('RESPONSE addSeriePhotoResponse', addSeriePhotoResponse);

			setIsLoading(false);
			
			if (addSeriePhotoResponse.error) {
				setError(addSeriePhotoResponse.error);
				return;
			}

			if (addSeriePhotoResponse.message)
				setSuccess(addSeriePhotoResponse.message);

			// reset states
			setError('');
			setUploadedFile(null);
			setSeriePhotoTitle('');
			setSeriePhotoDate('');

			// refetch serie photos list
			requestSeriePhotos(currentSerie);

			handleCloseAddSeriePhoto();

		} catch (err: any) {
			console.log('CATCH ERR', err, err.message);

			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while file upload. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
	};


	// Add new gallery
	const handleCloseAddGallery = () => {
		setError(null);
		setShowAddGallery(false);
	};
	const handleShowAddGallery = () => setShowAddGallery(true);

	const handleGalleryTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setGalleryTitle(e.target.value);
	};

	const handleSubmitAddGallery = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setIsLoading(true);

		console.log('ICI QCASPAS handleSubmitAddGallery', uploadedFile, galleryTitle);
		
		if (!uploadedFile || !galleryTitle)
			return alert("Please fill all fields !");

		let formData = new FormData();
		formData.append('image_url', uploadedFile, uploadedFile?.name);

		try {
			const uploadResponse = await uploadPhoto(formData);

			console.log('RESPONSE uploadResponse', uploadResponse);
			if (uploadResponse.error) {
				setIsLoading(false);
				setError(uploadResponse.error);
				return;
			}

			if (uploadResponse.message)
				setSuccess(uploadResponse.message);

			if (!uploadResponse.filename) {
				setIsLoading(false);
				return alert("Server upload error !");
			}

			const addGalleryResponse = await addGallery({ name: galleryTitle, filename: uploadResponse.filename });

			console.log('RESPONSE addGalleryResponse', addGalleryResponse);

			setIsLoading(false);
			
			if (addGalleryResponse.error) {
				setError(addGalleryResponse.error);
				return;
			}

			if (addGalleryResponse.message)
				setSuccess(addGalleryResponse.message);

			// reset states
			setError('');
			setUploadedFile(null);
			setGalleryTitle('');

			// refetch galleries list
			requestGalleries();

			handleCloseAddGallery();

		} catch (err: any) {
			console.log('CATCH ERR', err, err.message);

			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while file upload for new gallery. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
		
	};

	// Add new gallery's photo
	const handleCloseAddGalleryPhoto = () =>{
		// setCurrentGallery(null);
		setError(null);
		setShowAddGalleryPhoto(false);
	}
	const handleShowAddGalleryPhoto = (galleryId:number) => {
		setCurrentGallery(galleryId);
		setShowAddGalleryPhoto(true);
	};

	const handleGalleryPhotoTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setGalleryPhotoTitle(e.target.value);
	};
	const handleGalleryPhotoDateChange = (e: ChangeEvent<HTMLInputElement>) => {
		setGalleryPhotoDate(e.target.value);
	};
	const handleGalleryPhotoLocationChange = (e: ChangeEvent<HTMLInputElement>) => {
		setGalleryPhotoLocation(e.target.value);
	};

	const handleSubmitAddGalleryPhoto = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true);

		console.log('ICI QCASPAS', currentGallery, uploadedFile, galleryPhotoTitle, galleryPhotoDate, galleryPhotoLocation);
		
		if (!currentGallery || !uploadedFile || !galleryPhotoTitle || !galleryPhotoDate || !galleryPhotoLocation)
			return alert("Please fill all fields !");

		let formData = new FormData();
		formData.append('image_url', uploadedFile, uploadedFile?.name);

		try {
			const uploadResponse = await uploadPhoto(formData);

			console.log('RESPONSE uploadResponse', uploadResponse);
			if (uploadResponse.error) {
				setIsLoading(false);
				setError(uploadResponse.error);
				return;
			}

			if (uploadResponse.message)
				setSuccess(uploadResponse.message);

			if (!uploadResponse.filename) {
				setIsLoading(false);
				return alert("Server upload error !");
			}

			if (!currentGallery) {
				setIsLoading(false);
				return alert("Select gallery");
			}

			const addGalleryPhotoResponse = await addGalleryPhoto(currentGallery, { title: galleryPhotoTitle, filename: uploadResponse.filename, date: galleryPhotoDate, location: galleryPhotoLocation });

			console.log('RESPONSE addGalleryPhotoResponse', addGalleryPhotoResponse);

			setIsLoading(false);
			
			if (addGalleryPhotoResponse.error) {
				setError(addGalleryPhotoResponse.error);
				return;
			}

			if (addGalleryPhotoResponse.message)
				setSuccess(addGalleryPhotoResponse.message);

			// reset states
			setError('');
			setUploadedFile(null);
			setGalleryPhotoTitle('');
			setGalleryPhotoDate('');
			setGalleryPhotoLocation('');

			// refetch gallery photos list
			requestGalleryPhotos(currentGallery);

			handleCloseAddGalleryPhoto();

		} catch (err: any) {
			console.log('CATCH ERR', err, err.message);

			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while file upload. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
	};

	// Add new expo
	const handleCloseAddExpo = () => {
		setError(null);
		setShowAddExpo(false);
	};
	const handleShowAddExpo = () => {
		setActiveKeys([]);
		setExpoTitle('');
		setExpoDescription('');
		setShowAddExpo(true);
	}

	const handleExpoTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setExpoTitle(e.target.value);
	};

	const handleExpoDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
		setExpoDescription(e.target.value);
	};

	const handleSubmitAddExpo = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setIsLoading(true);

		console.log('handleSubmitAddExpo', uploadedFile, expoTitle);
		
		if (!uploadedFile || !expoTitle)
			return alert("Please fill all fields !");

		let formData = new FormData();
		formData.append('image_url', uploadedFile, uploadedFile?.name);

		try {
			const uploadResponse = await uploadPhoto(formData);

			console.log('RESPONSE uploadResponse', uploadResponse);
			if (uploadResponse.error) {
				setIsLoading(false);
				setError(uploadResponse.error);
				return;
			}

			if (uploadResponse.message)
				setSuccess(uploadResponse.message);

			if (!uploadResponse.filename) {
				setIsLoading(false);
				return alert("Server upload error !");
			}

			const addExpoResponse = await addExpo({ name: expoTitle, filename: uploadResponse.filename, description: expoDescription });

			console.log('RESPONSE addExpoResponse', addExpoResponse);

			setIsLoading(false);
			
			if (addExpoResponse.error) {
				setError(addExpoResponse.error);
				return;
			}

			if (addExpoResponse.message)
				setSuccess(addExpoResponse.message);

			// reset states
			setError('');
			setUploadedFile(null);
			setExpoTitle('');
			setExpoDescription('');

			// refetch expos list
			requestExpos();

			handleCloseAddExpo();

		} catch (err: any) {
			console.log('CATCH ERR', err, err.message);

			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while file upload for new expo. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
		
	};

	const handleSubmitUpdateExpo = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setIsLoading(true);

		console.log('handleSubmitUpdateExpo', currentExpo, expoTitle, expoDescription);
		
		if (!currentExpo || !expoTitle)
			return alert("Title can't be empty !");

		try {

			const updateExpoResponse = await updateExpo(currentExpo, { name: expoTitle, description: expoDescription });

			console.log('RESPONSE updateExpoResponse', updateExpoResponse);

			setIsLoading(false);
			
			if (updateExpoResponse.error) {
				setError(updateExpoResponse.error);
				return;
			}

			if (updateExpoResponse.message)
				setSuccess(updateExpoResponse.message);

			// reset states
			setError('');

			// refetch expos list
			requestExpos();
			alert('Updated !');

		} catch (err: any) {
			console.log('CATCH ERR', err, err.message);

			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while updating expo. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
		
	};

	// Add new expo's photo
	const handleCloseAddExpoPhoto = () =>{
		// setCurrentExpo(null);
		setError(null);
		setShowAddExpoPhoto(false);
	}
	const handleShowAddExpoPhoto = (expoId:number) => {
		setCurrentExpo(expoId);
		setShowAddExpoPhoto(true);
	};

	const handleExpoPhotoTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setExpoPhotoTitle(e.target.value);
	};
	const handleExpoPhotoDateChange = (e: ChangeEvent<HTMLInputElement>) => {
		setExpoPhotoDate(e.target.value);
	};
	const handleExpoPhotoLocationChange = (e: ChangeEvent<HTMLInputElement>) => {
		setExpoPhotoLocation(e.target.value);
	};

	const handleSubmitAddExpoPhoto = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true);

		console.log('ICI QCASPAS', currentExpo, uploadedFile, expoPhotoTitle, expoPhotoDate, expoPhotoLocation);
		
		if (!currentExpo || !uploadedFile || !expoPhotoTitle || !expoPhotoDate || !expoPhotoLocation)
			return alert("Please fill all fields !");

		let formData = new FormData();
		formData.append('image_url', uploadedFile, uploadedFile?.name);

		try {
			const uploadResponse = await uploadPhoto(formData);

			console.log('RESPONSE uploadResponse', uploadResponse);
			if (uploadResponse.error) {
				setIsLoading(false);
				setError(uploadResponse.error);
				return;
			}

			if (uploadResponse.message)
				setSuccess(uploadResponse.message);

			if (!uploadResponse.filename) {
				setIsLoading(false);
				return alert("Server upload error !");
			}

			if (!currentExpo) {
				setIsLoading(false);
				return alert("Select expo");
			}

			const addExpoPhotoResponse = await addExpoPhoto(currentExpo, { title: expoPhotoTitle, filename: uploadResponse.filename, date: expoPhotoDate, location: expoPhotoLocation });

			console.log('RESPONSE addExpoPhotoResponse', addExpoPhotoResponse);

			setIsLoading(false);
			
			if (addExpoPhotoResponse.error) {
				setError(addExpoPhotoResponse.error);
				return;
			}

			if (addExpoPhotoResponse.message)
				setSuccess(addExpoPhotoResponse.message);

			// reset states
			setError('');
			setUploadedFile(null);
			setExpoPhotoTitle('');
			setExpoPhotoDate('');
			setExpoPhotoLocation('');

			// refetch expo photos list
			requestExpoPhotos(currentExpo);

			handleCloseAddExpoPhoto();

		} catch (err: any) {
			console.log('CATCH ERR', err, err.message);

			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while file upload. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
	};

	const selectSerie = (serieId:any) => {
		setSelectedSeriePhotos([]);
		setCurrentSerie(serieId);
		requestSeriePhotos(serieId);
	}

	const selectExpo = (exposition:any) => {
		if (activeKeys.includes(exposition)) {
			setActiveKeys([])
			setSelectedExpoPhotos([]);
			setCurrentExpo(null);
			setExpoTitle('');
			setExpoDescription('');
		} else {
			setActiveKeys([exposition]);
			setSelectedExpoPhotos([]);
			setCurrentExpo(exposition.id);
			requestExpoPhotos(exposition.id);
			setExpoTitle(exposition.name);
			setExpoDescription(exposition.description);
		}
	}


	const selectGallery = (galleryId:any) => {
		setSelectedGalleryPhotos([]);
		setCurrentGallery(galleryId);
		requestGalleryPhotos(galleryId);
	}

	// Request serie's data

	const requestSeries = async () => {
		
		setIsLoading(true);
		
		try {
			const response = await getSeries();
			console.log('RESPONSE requestSeries', response);

			setIsLoading(false);

			if (response.error) {
				console.log('ERROR requestSeries', response.error);
				setError(response.error);
				return;
			}

			if (response.length > 0)
				setSeries(response);
			else
				setSeries([]);

		} catch (err: any) {
			console.log('CATCH ERR requestSeries', err, err.message);
			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while getting series. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
	}

	const requestSeriePhotos = async (serieId:any) => {

		console.log('EVENT KEY serieId', serieId);

		setIsLoading(true);

		try {
			const response = await getSeriePhotos(serieId);
			
			console.log('RESPONSE requestSeriePhotos', response);

			setIsLoading(false);

			if (response.error) {
				console.log('ERROR requestSeriePhotos', response.error);
				setError(response.error);
				return;
			}

			if (response.length > 0) {
				let formatArray:{ id:number; src:string; width:number; height:number }[] = [];
				response.forEach((photo:any, photoIndex:number) => {
					formatArray.push({ id: photo.id, src: `${BASE_URL}/photos/o/`+photo.filename, width: 1, height: 1 });
				})
				setSeriePhotos(formatArray);
			} else {
				setSeriePhotos([]);
			}

		} catch (err: any) {
			console.log('CATCH ERR requestSeriePhotos', err, err.message);
			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while getting serie photos. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
	}


	// Request gallery's data

	const requestGalleries = async () => {
		
		setIsLoading(true);
		
		try {
			const response = await getGalleries();
			console.log('RESPONSE requestGalleries', response);

			setIsLoading(false);

			if (response.error) {
				console.log('ERROR requestGalleries', response.error);
				setError(response.error);
				return;
			}

			if (response.length > 0)
				setGalleries(response);
			else
				setGalleries([]);

		} catch (err: any) {
			console.log('CATCH ERR requestGalleries', err, err.message);
			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while getting galleries. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
	}

	const requestGalleryPhotos = async (galleryId:any) => {

		console.log('EVENT KEY galleryId', galleryId);

		setIsLoading(true);

		try {
			const response = await getGalleryPhotos(galleryId);
			
			console.log('RESPONSE requestGalleryPhotos', response);

			setIsLoading(false);

			if (response.error) {
				console.log('ERROR requestGalleryPhotos', response.error);
				setError(response.error);
				return;
			}

			if (response.length > 0) {
				let formatArray:{ id:number, src:string; width:number; height:number }[] = [];
				response.forEach((photo:any, photoIndex:number) => {
					formatArray.push({ id: photo.id, src: `${BASE_URL}/photos/o/`+photo.filename, width: 1, height: 1 });
				})
				setGalleryPhotos(formatArray);
			} else {
				setGalleryPhotos([]);
			}

		} catch (err: any) {
			console.log('CATCH ERR requestGalleryPhotos', err, err.message);
			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while getting gallery photos. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
	}

	// Request expo's data

	const requestExpos = async () => {
		
		setIsLoading(true);
		
		try {
			const response = await getExpos();
			console.log('RESPONSE requestExpos', response);

			setIsLoading(false);

			if (response.error) {
				console.log('ERROR requestExpos', response.error);
				setError(response.error);
				return;
			}

			if (response.length > 0)
				setExpositions(response);
			else
				setExpositions([]);

		} catch (err: any) {
			console.log('CATCH ERR requestExpos', err, err.message);
			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while getting expos. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
	}

	const requestExpoPhotos = async (expoId:any) => {

		console.log('EVENT KEY expoId', expoId);

		setIsLoading(true);

		try {
			const response = await getExpoPhotos(expoId);
			
			console.log('RESPONSE requestExpoPhotos', response);

			setIsLoading(false);

			if (response.error) {
				console.log('ERROR requestExpoPhotos', response.error);
				setError(response.error);
				return;
			}

			if (response.length > 0) {
				let formatArray:{ id:number, src:string; width:number; height:number }[] = [];
				response.forEach((photo:any, photoIndex:number) => {
					formatArray.push({ id: photo.id, src: `${BASE_URL}/photos/o/`+photo.filename, width: 1, height: 1 });
				})
				setExpoPhotos(formatArray);
			} else {
				setExpoPhotos([]);
			}

		} catch (err: any) {
			console.log('CATCH ERR requestExpoPhotos', err, err.message);
			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while getting expo photos. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
	}

	const requestAuth = async () => {

		setIsLoading(true);

		try {
			const response = await checkAuth();
			
			console.log('RESPONSE requestAuth', response);

			setIsLoading(false);

			if (response.error) {
				console.log('ERROR requestAuth', response.error);
				setError(response.error);
				return;
			}

		} catch (err: any) {
			console.log('CATCH ERR requestAuth', err, err.message);
			setIsLoading(false);

			if (err && err.message)
				setError(err.message);
			else
				setError('An error occurred while checking auth. Please try again later.');

			if (err.message === 'Unauthorized') {
				logoutRequest();
			}
		}
	}
	

	useEffect(() => {
		requestAuth();
	},[]);
	
	useEffect(() => {
		requestSeries();
		requestExpos();
		requestGalleries();
	},[]);


	return (
		<Container fluid className="p-5 mt-5">
			<Row>
				<Col md={2}>
					<Row>
						<button style={{
							border: 0,
							backgroundColor: selected === 'series' ? 'lightgrey' : 'transparent',
							borderRadius: 3,
							color:  selected === 'series' ? 'white' : 'black',
							padding: 15
						}} onClick={() => setSelected('series')}>
							<Row>
								<Col md={8} style={{display: 'flex', textAlign: 'start', alignItems: 'center'}}>
									Series
								</Col>
								<Col md={4}>
									<div style={{
										backgroundColor: series.length > 0 ? 'darksalmon' : 'lightgrey',
										padding: 9,
										color: 'white',
										borderRadius: 3
									}}>
										{ series.length }
									</div>
								</Col>
							</Row>
						</button>
					</Row>
					<Row>
						<button style={{
							border: 0,
							backgroundColor: selected === 'expositions' ? 'lightgrey' : 'transparent',
							borderRadius: 3,
							color:  selected === 'expositions' ? 'white' : 'black',
							padding: 15
						}} onClick={() => setSelected('expositions')}>
							<Row>
								<Col md={8} style={{display: 'flex', textAlign: 'start', alignItems: 'center'}}>
									Expositions
								</Col>
								<Col md={4}>
									<div style={{
										backgroundColor: expositions.length > 0 ? 'darksalmon' : 'lightgrey',
										padding: 9,
										color: 'white',
										borderRadius: 3
									}}>
										{ expositions.length }
									</div>
								</Col>
							</Row>
						</button>
					</Row>
					<Row>
						<button style={{
							border: 0,
							backgroundColor: selected === 'galleries' ? 'lightgrey' : 'transparent',
							borderRadius: 3,
							color:  selected === 'galleries' ? 'white' : 'black',
							padding: 15
						}} onClick={() => setSelected('galleries')}>
							<Row>
								<Col md={8} style={{display: 'flex', textAlign: 'start', alignItems: 'center'}}>
									galleries
								</Col>
								<Col md={4}>
									<div style={{
										backgroundColor: galleries.length > 0 ? 'darksalmon' : 'lightgrey',
										padding: 9,
										color: 'white',
										borderRadius: 3
									}}>
										{ galleries.length }
									</div>
								</Col>
							</Row>
						</button>
					</Row>
				</Col>
				<Col md={8}>
					{
						selected === 'series' ?
							<Accordion onSelect={(eventKey) => eventKey && selectSerie(eventKey)}>
							{
								series.map((serie, serieIndex) =>
									  <Accordion.Item key={serieIndex} eventKey={serie.id}>
										<Accordion.Header>{serie.name}</Accordion.Header>
										<Accordion.Body>
											<Row>
												<Col md={{ span: 2}} className="mb-3">
													<Button variant="danger" onClick={() => handleDeleteSerie(serie.id)}>Supprimer la serie</Button>
												</Col>
												<Col md={{ span: 2, offset: 6}} className="mb-3">
													{ selectedSeriePhotos.length > 0 && <Button variant="danger" onClick={() => handleDeleteSeriePhotos()}>- Supprimer ({selectedSeriePhotos.length})</Button>}
												</Col>
												<Col md={{ span: 2}} className="mb-3">
													<Button variant="primary" onClick={() => handleShowAddSeriePhoto(serie.id)}>+ Ajouter une photo</Button>
												</Col>
											</Row>
											<Row>
												<Gallery photos={seriePhotos} renderImage={imageSerieRenderer} />
											</Row>
										</Accordion.Body>
									  </Accordion.Item>
								)
							}
							</Accordion>
						: null
					}
					{
						selected === 'expositions' ?
							<Accordion activeKey={activeKeys} onSelect={(eventKey) => eventKey && selectExpo(eventKey)}>
							{
								expositions.map((exposition, expositionIndex) =>
									  <Accordion.Item key={expositionIndex} eventKey={exposition}>
										<Accordion.Header>{exposition.name}</Accordion.Header>
										<Accordion.Body>
													<Row>
														<Col md={{ span: 2}} className="mb-3">
															<Button variant="danger" onClick={() => handleDeleteExpo(exposition.id)}>Supprimer l'exposition</Button>
														</Col>
														<Col md={{ span: 2, offset: 6}} className="mb-3">
															{ selectedExpoPhotos.length > 0 && <Button variant="danger" onClick={() => handleDeleteExpoPhotos()}>- Supprimer ({selectedExpoPhotos.length})</Button>}
														</Col>
														<Col md={{ span: 2}} className="mb-3">
															<Button variant="primary" onClick={() => handleShowAddExpoPhoto(exposition.id)}>+ Ajouter une photo</Button>
														</Col>
													</Row>
											<ListGroup>
												<ListGroup.Item>
													<Row>
														<Col>
															<Form onSubmit={handleSubmitUpdateExpo}>
																<Row>
																	<Form.Group as={Row} controlId="formBasicTitle">
																		<Form.Label column style={{textAlign: 'left'}}>Titre</Form.Label>
																		<Form.Control type="text" placeholder="Ex: Paris" defaultValue={exposition.name} onChange={handleExpoTitleChange} required/>
																  	</Form.Group>
																  	<Form.Group as={Row} controlId="formBasicDescription" className="mb-3">
																		<Form.Label column style={{textAlign: 'left'}}>Description</Form.Label>
																		<Form.Control as="textarea" rows={5} placeholder="Ex: Nouvelle exposition..." defaultValue={exposition.description} onChange={handleExpoDescriptionChange}/>
																  	</Form.Group>
																	<span style={{color: 'red'}}>{ error }</span>
																</Row>
																<Row>
																	<Col md={{ span: 2, offset: 10}} className="mb-3">
																		<Button variant="primary" type="submit" disabled={exposition.description === expoDescription && exposition.name === expoTitle}>
																			Enregistrer
																		</Button>
																	</Col>
																</Row>
															</Form>
														</Col>
													</Row>
												</ListGroup.Item>
												{
													expoPhotos.length > 0 ?
													<ListGroup.Item>
														<Row>
															<Gallery photos={expoPhotos} renderImage={imageExpoRenderer} />
														</Row>
													</ListGroup.Item>
													: null
												}
											</ListGroup>
										</Accordion.Body>
									  </Accordion.Item>
								)
							}
							</Accordion>
						: null
					}
					{
						selected === 'galleries' ?
							<Accordion onSelect={(eventKey) => eventKey && selectGallery(eventKey)}>
							{
								galleries.map((gallery, galleryIndex) =>
									  <Accordion.Item key={galleryIndex} eventKey={gallery.id}>
										<Accordion.Header>{gallery.name}</Accordion.Header>
										<Accordion.Body>
											<Row>
												<Col md={{ span: 2}} className="mb-3">
													<Button variant="danger" onClick={() => handleDeleteGallery(gallery.id)}>Supprimer l'gallery</Button>
												</Col>
												<Col md={{ span: 2, offset: 6}} className="mb-3">
													{ selectedGalleryPhotos.length > 0 && <Button variant="danger" onClick={() => handleDeleteGalleryPhotos()}>- Supprimer ({selectedGalleryPhotos.length})</Button>}
												</Col>
												<Col md={{ span: 2}} className="mb-3">
													<Button variant="primary" onClick={() => handleShowAddGalleryPhoto(gallery.id)}>+ Ajouter une photo</Button>
												</Col>
											</Row>
											<Row>
												<Gallery photos={galleryPhotos} renderImage={imageGalleryRenderer} />
											</Row>
										</Accordion.Body>
									  </Accordion.Item>
								)
							}
							</Accordion>
						: null
					}
				</Col>
				<Col md={2}>
					{
						selected === 'series' ?
							<Button variant="dark" onClick={handleShowAddSerie}>+ Ajouter une serie</Button>
						: selected === 'expositions' ?
							<Button variant="dark" onClick={handleShowAddExpo}>+ Ajouter une exposition</Button>
						: selected === 'galleries' ?
							<Button variant="dark" onClick={handleShowAddGallery}>+ Ajouter une gallery</Button>
						: null
					}
				</Col>
			</Row>
			{/*Series modals*/}
			<Modal show={showAddSerie} onHide={handleCloseAddSerie}>
				<Modal.Header closeButton>
					<Modal.Title>Nouvelle série</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleSubmitAddSerie}>
					<Modal.Body>
						  	<Form.Group className="mb-3" controlId="formBasicTitle">
								<Form.Label>Titre</Form.Label>
								<Form.Control type="text" placeholder="Entrer titre" onChange={handleSerieTitleChange} required/>
						  	</Form.Group>
							<span style={{color: 'red'}}>{ error }</span>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseAddSerie}>
							Fermer
						</Button>
						<Button variant="primary" type="submit">
							Enregistrer
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={showAddSeriePhoto} onHide={handleCloseAddSeriePhoto}>
				<Modal.Header closeButton>
					<Modal.Title>Nouvelle photo</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleSubmitAddSeriePhoto}>
					<Modal.Body>
						  	<Form.Group className="mb-3" controlId="formBasicTitle">
								<Form.Label>Titre</Form.Label>
								<Form.Control type="text" placeholder="Entrer titre" onChange={handleSeriePhotoTitleChange} required />
						  	</Form.Group>
						  	<Form.Group className="mb-3" controlId="formBasicDate">
								<Form.Label>Date</Form.Label>
								<Form.Control type="date" placeholder="Date" onChange={handleSeriePhotoDateChange} required />
						  	</Form.Group>
						  	<Form.Group controlId="formBasicFile" className="mb-3">
						        <Form.Label>Selectionnez une photo</Form.Label>
						        <Form.Control type="file" accept="image/*" onChange={handleInputPhotoChange} required />
						    </Form.Group>
							<span style={{color: 'red'}}>{ error }</span>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseAddSeriePhoto}>
							Fermer
						</Button>
						<Button variant="primary" type="submit">
							Enregistrer
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			{/*Expos modals*/}
			<Modal show={showAddExpo} onHide={handleCloseAddExpo}>
				<Modal.Header closeButton>
					<Modal.Title>Nouvelle exposition</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleSubmitAddExpo}>
					<Modal.Body>
						  	<Form.Group className="mb-3" controlId="formBasicTitle">
								<Form.Label>Titre (Ville)</Form.Label>
								<Form.Control type="text" placeholder="Ex: Paris" onChange={handleExpoTitleChange} required/>
						  	</Form.Group>
						  	<Form.Group className="mb-3" controlId="formBasicDescription">
								<Form.Label>Description</Form.Label>
								<Form.Control as="textarea" rows={5} placeholder="Ex: Nouvelle exposition..." onChange={handleExpoDescriptionChange}/>
						  	</Form.Group>
						  	<Form.Group controlId="formBasicFile" className="mb-3">
						        <Form.Label>Selectionnez une image</Form.Label>
						        <Form.Control type="file" accept="image/*" onChange={handleInputPhotoChange} required />
						    </Form.Group>
							<span style={{color: 'red'}}>{ error }</span>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseAddExpo}>
							Fermer
						</Button>
						<Button variant="primary" type="submit">
							Enregistrer
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={showAddExpoPhoto} onHide={handleCloseAddExpoPhoto}>
				<Modal.Header closeButton>
					<Modal.Title>Nouvelle photo</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleSubmitAddExpoPhoto}>
					<Modal.Body>
						  	<Form.Group className="mb-3" controlId="formBasicTitle">
								<Form.Label>Titre</Form.Label>
								<Form.Control type="text" placeholder="Entrer titre" onChange={handleExpoPhotoTitleChange} required />
						  	</Form.Group>
						  	<Form.Group className="mb-3" controlId="formBasicDate">
								<Form.Label>Date</Form.Label>
								<Form.Control type="date" placeholder="Date" onChange={handleExpoPhotoDateChange} required />
						  	</Form.Group>
						  	<Form.Group className="mb-3" controlId="formBasicCity">
								<Form.Label>Ville</Form.Label>
								<Form.Control type="text" placeholder="Ex: Paris" onChange={handleExpoPhotoLocationChange} required />
						  	</Form.Group>
						  	<Form.Group controlId="formBasicFile" className="mb-3">
						        <Form.Label>Selectionnez une photo</Form.Label>
						        <Form.Control type="file" accept="image/*" onChange={handleInputPhotoChange} required />
						    </Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseAddExpoPhoto}>
							Fermer
						</Button>
						<Button variant="primary" type="submit">
							Enregistrer
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			{/*galleries modals*/}
			<Modal show={showAddGallery} onHide={handleCloseAddGallery}>
				<Modal.Header closeButton>
					<Modal.Title>Nouvelle gallery</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleSubmitAddGallery}>
					<Modal.Body>
						  	<Form.Group className="mb-3" controlId="formBasicTitle">
								<Form.Label>Titre (Ville)</Form.Label>
								<Form.Control type="text" placeholder="Ex: Paris" onChange={handleGalleryTitleChange} required/>
						  	</Form.Group>
						  	<Form.Group controlId="formBasicFile" className="mb-3">
						        <Form.Label>Selectionnez une image</Form.Label>
						        <Form.Control type="file" accept="image/*" onChange={handleInputPhotoChange} required />
						    </Form.Group>
							<span style={{color: 'red'}}>{ error }</span>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseAddGallery}>
							Fermer
						</Button>
						<Button variant="primary" type="submit">
							Enregistrer
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={showAddGalleryPhoto} onHide={handleCloseAddGalleryPhoto}>
				<Modal.Header closeButton>
					<Modal.Title>Nouvelle photo</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleSubmitAddGalleryPhoto}>
					<Modal.Body>
						  	<Form.Group className="mb-3" controlId="formBasicTitle">
								<Form.Label>Titre</Form.Label>
								<Form.Control type="text" placeholder="Entrer titre" onChange={handleGalleryPhotoTitleChange} required />
						  	</Form.Group>
						  	<Form.Group className="mb-3" controlId="formBasicDate">
								<Form.Label>Date</Form.Label>
								<Form.Control type="date" placeholder="Date" onChange={handleGalleryPhotoDateChange} required />
						  	</Form.Group>
						  	<Form.Group className="mb-3" controlId="formBasicCity">
								<Form.Label>Ville</Form.Label>
								<Form.Control type="text" placeholder="Ex: Paris" onChange={handleGalleryPhotoLocationChange} required />
						  	</Form.Group>
						  	<Form.Group controlId="formBasicFile" className="mb-3">
						        <Form.Label>Selectionnez une photo</Form.Label>
						        <Form.Control type="file" accept="image/*" onChange={handleInputPhotoChange} required />
						    </Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseAddGalleryPhoto}>
							Fermer
						</Button>
						<Button variant="primary" type="submit">
							Enregistrer
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Container>
	);
};

export default Home;